import { faPhone, faEnvelope, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp, faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";

const indexRoutes = [
    {
        name: "Inicio",
        route: "#inicio",
    },
    {
        name: "Nuestra historia",
        route: "#nuestrahistoria",
    },
    {
        name: "Objetivos",
        route: "#objetivos",
    },
    {
        name: "Productos y servicios",
        route: "#productosyservicios",
    },
    {
        name: "Contacto",
        route: "#contacto",
    },
];

const PHONE_NUMBER = +528111849913;
const EMAIL = 'ventas@imprentadeloriente.com';
const MAP =
    "https://www.google.com/maps/dir//Imprenta+Del+Oriente/data=!4m8!4m7!1m0!1m5!1m1!1s0x8662eaa7970a8e93:0x583a6e1b77d3c851!2m2!1d-100.2467508!2d25.6811403";


/*
    {
        name: "Teléfono",
        icon: faPhone,
        route: `tel:${PHONE_NUMBER}`,
    },
*/
const socialMediaRoutes = [
    {
        name: "Whatsapp",
        icon: faWhatsapp,
        route: `https://wa.me/${PHONE_NUMBER}`,
    },
    {
        name: "Facebook",
        icon: faFacebookF,
        route: `https://www.facebook.com/ImprentadelOriente`,
    },
    {
        name: "Instagram",
        icon: faInstagram,
        route: `https://www.instagram.com/imprentadeloriente/`,
    },
    {
        name: "Email",
        icon: faEnvelope,
        route: `mailto:${EMAIL}`,
    },
    {
        name: "Mapa",
        icon: faMapMarkerAlt,
        route: MAP,
    },
];

const sendTo = (route) => {
    window.location.href = route;
};

export { indexRoutes, socialMediaRoutes, sendTo, PHONE_NUMBER };
