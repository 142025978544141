import styled from "styled-components";


const Container = styled.div`
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    background-color: white;
    box-shadow: -10px 1px 35px -5px rgba(0, 0, 0, 0.14);
    -webkit-box-shadow: -10px 1px 35px -5px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: -10px 1px 35px -5px rgba(0, 0, 0, 0.14);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
`;

const Route = styled.span`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    width: 100%;
    transition: all 0.3s ease;
    &:hover {
        background-color: #eaeaea;
    }
    ${".icon"} {
        color: #707070;
        font-size: 18px;
    }
`;


const Wrapper = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 60px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    @media (max-width: 940px) {
        width: 40px;
    }
    @media (max-width: 540px) {
        display: none;
        align-items: flex-end;
        justify-content: flex-end;
        left: 0;
        width: 100%;
        ${Container} {
            width: 100%;
            flex-direction: row;
            padding: 0;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            ${Route} {
                padding: 20px;
            }
        }
    }
`;

export {
    Wrapper,
    Container,
    Route
}