import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Wrapper, Info, Title, Text, Container, SubTitle, Button, Frame, Gradient } from "./Cover.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { sendTo, PHONE_NUMBER } from "../../utils/routes";

const Cover = () => {
    const data = useStaticQuery(
        graphql`
            query {
                cover: file(relativePath: { eq: "imprenta_del_oriente_mty.jpg" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `
    );

    // Set ImageData.
    const image = data.cover.childImageSharp.fluid;

    return (
        <Wrapper fluid={image} Tag="section" preserveStackingContext>
            <Container id="inicio">
                <Info>
                    <Title>IMPRIME TODO</Title>
                    <SubTitle>EN UN SOLO LUGAR</SubTitle>
                    <Text>
                        Invierte menos tiempo en ordenar tus impresiones y más tiempo en hacer crecer tu empresa.
                    </Text>
                    {/*
                        <Button onClick={() => sendTo(`tel:${PHONE_NUMBER}`)}>
                        Llamar
                        <FontAwesomeIcon icon={faPhone} className="icon" />
                    </Button>
                        */}
                </Info>
                <Frame />
                <Gradient />
            </Container>
        </Wrapper>
    );
};

export default Cover;
