import styled from "styled-components";
import { BuildingImage, Blue, Title, Text, Card, Gradient } from "../../styles/styled";

const Wrapper = styled.section`
    padding: 100px 80px 0px 80px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    grid-template-rows: 1fr 40px auto 40px 1fr;
    ${Gradient} {
        grid-column: 1/18;
        grid-row: 2/5;
    }
    ${Card} {
        grid-row: 3;
        grid-column: 2/16;
    }
    ${BuildingImage} {
        grid-column: 14/25;
        grid-row: 1/6;
    }
    @media (max-width: 1442px) {
        ${Card} {
            grid-row: 3;
            grid-column: 2/20;
        }
        ${Gradient} {
            grid-column: 1/23;
            grid-row: 2/5;
        }
        ${BuildingImage} {
            grid-column: 7/25;
            grid-row: 1/6;
        }
    }
    @media (max-width: 1063px) {
        grid-template-rows: 90px 40px auto 40px 90px;
        ${Card} {
            grid-row: 3;
            grid-column: 2/22;
        }
        ${Gradient} {
            grid-column: 1/23;
            grid-row: 2/5;
        }
        ${BuildingImage} {
            max-height: 100%;
        }
    }
    @media (max-width: 940px) {
        padding: 100px 30px 0px 30px;
    }
    @media (max-width: 480px) {
        padding: 60px 0 0 0;
        ${Card} {
            grid-row: 3;
            grid-column: 1/24;
        }
        ${Gradient} {
            grid-column: 1/25;
            grid-row: 2/5;
        }
        ${BuildingImage} {
            grid-column: 1/25;
            grid-row: 1/6;
        }
    }
    @media (max-width: 400px) {
        ${Card} {
            grid-row: 3;
            grid-column: 1/25;
        }
    }
`;

export { Wrapper, Gradient, Card, Title, Text, Blue, BuildingImage };
