import styled from "styled-components";
import Img from "gatsby-image";

const Main = styled.main`
    padding-top: 60px;
`;

const SolidGradient = styled.div`
    background: rgb(162, 255, 0);
    background: linear-gradient(180deg, rgba(162, 255, 0, 1) 0%, rgba(0, 111, 90, 1) 100%);
`;

const Gradient = styled.div`
    background: rgb(162, 255, 0);
    background: linear-gradient(180deg, rgba(162, 255, 0, 1) 0%, rgba(0, 111, 90, 1) 100%);
    opacity: 0.67;
    height: 100%;
    width: 100%;
    z-index: 9;
`;

const Frame = styled.div`
    border: 10px solid #2f3744;
`;

const Card = styled.div`
    z-index: 10;
    background-color: white;
    padding: 30px 40px;
    border-radius: 5px;
    box-shadow: -10px 1px 35px -5px rgba(0, 0, 0, 0.14);
    -webkit-box-shadow: -10px 1px 35px -5px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: -10px 1px 35px -5px rgba(0, 0, 0, 0.14);
    @media (max-width: 400px) {
        padding: 20px;
    }
`;

const Text = styled.p`
    color: #393939;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 35px;
    @media (max-width: 940px) {
        font-size: 18px;
    }
    @media (max-width: 480px) {
        line-height: normal;
    }
`;

const Title = styled.h3`
    color: #393939;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 32px;
    margin: 0;
    border-left: 8px solid #0076de;
    padding: 10px;
    margin-bottom: 40px;
`;

const Blue = styled.span`
    display: inline;
    color: #128ccb !important;
    font-family: Arial, Helvetica, sans-serif;
`;

const BuildingImage = styled(Img)`
    z-index: 1;
`;

export { Main, BuildingImage, Blue, Title, Text, Card, Gradient, Frame, SolidGradient };
