import React from "react";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import { Wrapper } from "./MainLogo.styled";


const MainLogo = () => {
    const logo = useStaticQuery(graphql`
        query {
            cono: file(relativePath: { eq: "logo.png" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    return (
        <Wrapper>
            <Img fluid={logo.cono.childImageSharp.fluid} alt="Imprenta del oriente" className="logo" />
        </Wrapper>
    );
};

export default MainLogo;
