import React from "react";
import {
    Wrapper,
    Title,
    Subtitle,
    ContactCard,
    Value,
    FlagContainer,
    Flag,
    Route,
    SocialWrapper,
    Group,
    AdressContainer
} from "./Contact.styled";
import mex from "../../images/mex.jpg";
import usa from "../../images/usa.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sendTo, socialMediaRoutes } from "../../utils/routes";

const Contact = () => {

    const data = [
        {   
            flag: mex,
            state: "Gpe. N.L",
            street: "Kepler 102.",
            address: "Fracc. Nueva Exposición.",
            cp: "CP 67150",
            phone: "81.8337.7090",
            people: [
                {
                    name: "Ing. Mauricio Rodríguez Salas",
                    email: "mauricio@imprentadeloriente.com"
                },
                {
                    name: "Lic. Norma Rodríguez Salas",
                    email: "norma@imprentadeloriente.com"
                },
                {
                    name: "C.P. Rubén Rodríguez Salas",
                    email: "rubenjr@imprentadeloriente.com"
                },
            ],
        },
        {   
            flag: usa,
            state: "Pharr. TX.",
            street: "2601 Salamanca St.",
            address: "Subdivisión Nueva España.",
            cp: "CP 78577",
            phone: "(956) 225.4635",
            people: [
                {
                    name: "C.P. Elsa Griselda Rodríguez Salas",
                    email: "elsacantu@imprentadeloriente.com"
                },
            ],
        }
    ];

    return (
        <Wrapper id="contacto">
            <Title>Contacto</Title>
            <Subtitle>Negociadores de Contratos</Subtitle>

            {data.map((group, index) => (
                <Group key={index}>
                    <FlagContainer>
                        <Flag src={group.flag} />
                        <Value bold>{group.state}</Value>
                    </FlagContainer>

                    <AdressContainer>
                        <Value>{group.street}</Value>
                        <Value>{group.address}</Value>
                        <Value>{group.cp}</Value>
                        <Value>{group.phone}</Value>
                    </AdressContainer>
                    {group.people.map((el, index) => (
                        <ContactCard key={index} onClick={() => sendTo(`mailto:${el.email}`)}>
                            <Value bold>{el.name}</Value>
                            <Value>{el.email}</Value>
                        </ContactCard>
                    ))}
                </Group>
            ))}
            <SocialWrapper>
                {socialMediaRoutes.map((route) => (
                    <Route onClick={() => sendTo(route.route)}>
                        <FontAwesomeIcon icon={route.icon} className="icon" />
                    </Route>
                ))}{" "}
            </SocialWrapper>
        </Wrapper>
    );
};

export default Contact;

/*
{data.map((el, index) => (
                    <ContactCard key={index} first={index === 0}>
                        <Value bold>{el.name}</Value>
                        <FlagContainer>
                            <Flag src={el.flag} />
                            <Value bold>{el.state}</Value>
                        </FlagContainer>
                        <Value>{el.street}</Value>
                        <Value>{el.address}</Value>
                        <Value>{el.cp}</Value>
                        <Value>{el.phone}</Value>
                    </ContactCard>
                ))}
*/
