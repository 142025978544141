import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Wrapper, LogoContainer, OptionsContainer, NavRoute, IconContainer } from "./navbar.styled";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import { indexRoutes, sendTo } from "../../utils/routes";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
    const [showburgerMenu, setshowburgerMenu] = useState(false);

    const logo = useStaticQuery(graphql`
        query {
            cono: file(relativePath: { eq: "logo.png" }) {
                childImageSharp {
                    fluid(quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    const toggleRoutes = () => {
        setshowburgerMenu(!showburgerMenu);
    };

    const handleRoute = (route) => {
        setshowburgerMenu(false);
        //sendTo(route.route);
    };

    return (
        <Wrapper>
            <LogoContainer>
                <Img fluid={logo.cono.childImageSharp.fluid} alt="Imprenta del oriente" className="logo" />
            </LogoContainer>
            <IconContainer onClick={toggleRoutes}>
                <FontAwesomeIcon icon={showburgerMenu ? faTimes : faBars} className="icon" />
            </IconContainer>
            <OptionsContainer show={showburgerMenu}>
                {indexRoutes.map((route) => (
                    <NavRoute onClick={() => handleRoute(route.route)} href={route.route}>{route.name}</NavRoute>
                ))}
            </OptionsContainer>
        </Wrapper>
    );
};

export default Navbar;
