import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Wrapper, Frame, Gradient, Container, Card, Title, Text, JustifiedText } from "./ProblemasSolucion.styled";

const ProblemasSolucion = () => {
    const data = useStaticQuery(graphql`
        query {
            building: file(relativePath: { eq: "imprentas_en_monterrey.jpg" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    // Set ImageData.
    const image = data.building.childImageSharp.fluid;

    return (
        <Wrapper fluid={image} Tag="section" preserveStackingContext>
            <Container>
                <Frame />
                <Gradient />
                <Card>
                    <Title>Problemas con las imprentas y la solución</Title>
                    <Text>"No me entregaron a tiempo, no me respetaron el precio que se me dio".</Text>
                    <Text>¿Por qué crees que pasa eso con las imprentas? Te tengo la respuesta...</Text>
                    <Text>
                        Desgraciadamente, el gremio de los impresores se ve afectador por negocios informales en donde
                        no existe la honestidad y el compromiso.
                    </Text>
                    <Text>AQUÍ ES DONDE ENTRAMOS NOSOTROS, PARA EVITAR ESTE INCONVENIENTE</Text>
                    <JustifiedText space>Nosotros, Imprenta del Oriente, S.A. de C.V.</JustifiedText>
                    <JustifiedText>SÍ estamos comprometidos contigo a ofrecerte un servicio de calidad.</JustifiedText>
                </Card>
            </Container>
        </Wrapper>
    );
};

export default ProblemasSolucion;
