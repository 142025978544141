import styled from "styled-components";
import {BuildingImage, Blue, Title, Text, Card, Gradient } from "../../styles/styled";

const Wrapper = styled.section`
    padding: 100px 80px 0px 80px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    grid-template-rows: 0.3fr 40px auto 40px 0.3fr;
    ${Card} {
        grid-row: 3;
        grid-column: 8/25;
    }
    ${Gradient} {
        grid-column: 6/24;
        grid-row: 2/5;
    }
    ${BuildingImage} {
        grid-column: 1/16;
        grid-row: 1/6;
    }
    @media (max-width: 1513px) {
        grid-template-rows: 1fr 40px auto 40px 1fr;
        ${Card} {
            grid-column: 6/25;
            grid-row: 3;
        }
        ${Gradient} {
            grid-column: 4/25;
            grid-row: 2/5;
        }
        ${BuildingImage} {
            grid-column: 1/22;
            grid-row: 1/6;
        }
    }
    @media (max-width: 1265px) {
        grid-template-rows: 90px 40px auto 40px 90px;
        ${Card} {
            grid-row: 3;
            grid-column: 1/24;
        }
        ${Gradient} {
            grid-column: 4/25;
            grid-row: 2/5;
        }
        ${BuildingImage} {
            max-height: 100%;
            grid-column: 3/24;
        }
    }

    @media (max-width: 969px) {
        ${Card} {
            grid-column: 1/25;
        }
    }
    @media (max-width: 940px) {
        padding: 100px 30px 0px 30px;
    }
    @media (max-width: 580px) {
        grid-template-rows: 40px 20px auto 20px 40px;
        ${Card} {
            grid-row: 3;
            grid-column: 1/25;
        }
        ${Gradient} {
            grid-column: 1/23;
            grid-row: 2/5;
        }
        ${BuildingImage} {
            grid-column: 1/25;
        }
    }
    @media (max-width: 480px) {
        padding: 60px 0 0 0;
        ${Card} {
            grid-row: 3;
            grid-column: 2/25;
        }
        ${Gradient} {
            grid-column: 1/24;
            grid-row: 2/5;
        }
        ${BuildingImage} {
            grid-column: 1/25;
            grid-row: 1/6;
        }
    }
    @media (max-width: 400px) {
        grid-template-rows: 20px 20px auto 20px 20px;
        ${Card} {
            grid-row: 3;
            grid-column: 1/25;
        }
        ${Gradient} {
            grid-column: 1/16;
            grid-row: 2/6;
        }
    }
`;

export { Wrapper, Gradient, Card, Title, Text, Blue, BuildingImage };
