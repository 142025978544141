import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Wrapper, Gradient, Card, Title, Text, Blue, BuildingImage } from "./Objetivos.styled";

const Objetivos = () => {
    const image = useStaticQuery(graphql`
        query {
            building: file(relativePath: { eq: "imrprenta_del_oriente_impresiones_en_mty.jpg" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    return (
        <Wrapper id="objetivos">
            <Gradient />

            <Card>
                <Title>Objetivos</Title>
                <Text>
                    <Blue>Compromiso:</Blue> Es una obligación contraída, palabra dada: "no te vamos a fallar,
                    especialmente en tiempos de entrega y precios justos".
                </Text>
                <Text>
                    <Blue>Honestidad: </Blue>El valor de honestidad en los negocios es muy importante para crear una
                    relación a largo plazo con tu empresa.
                </Text>
                <Text>
                    <Blue>Respeto: </Blue>Es clave tratar siempre con respeto a las personas que forman parte del
                    proceso de negociación, ya que es importante conocer y cubrir sus necesidades y objetivos.
                </Text>
                <Text>Nuestros objetivos se han cumplido por 50 años en nuestra bella Ciudad Industrial.</Text>
            </Card>
            <BuildingImage fluid={image.building.childImageSharp.fluid} alt="Imprenta del oriente" className="image" />
        </Wrapper>
    );
};

export default Objetivos;
