import styled from "styled-components";

const Wrapper = styled.div`
    margin: 20px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${'.logo'}{
        //width: 500px;
        width: 400px;
    }
`;

export {
    Wrapper
}