import styled from "styled-components";

const Wrapper = styled.nav`
    width: 100%;
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0%;
    box-sizing: border-box;
    box-shadow: 1px 7px 19px 0px rgba(0, 0, 0, 0.03);
    -webkit-box-shadow: 1px 7px 19px 0px rgba(0, 0, 0, 0.03);
    -moz-box-shadow: 1px 7px 19px 0px rgba(0, 0, 0, 0.03);
    padding: var(--df-padding);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 100;
    background-color: white;
    @media (max-width: 940px) {
        padding: var(--940-padding);
    }
`;

const IconContainer = styled.div`
    height: 100%;
    box-sizing: border-box;
    display: none;
    ${".icon"} {
        padding: 20px 0px 20px 20px;
        transform: scale(1.4);
    }
    @media (max-width: 820px) {
        display: flex;
    }
`;

const LogoContainer = styled.div`
    ${".logo"} {
        width: 130px;
    }
`;

const OptionsContainer = styled.ul`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    list-style: none;
    height: 100%;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    @media (max-width: 822px) {
        > * {
            &:first-child {
                height: 50%;
            }
        }
        margin-top: 60px;
        flex-direction: column;
        height: calc(100vh - 60px);
        width: 50%;
        background-color: white;
        position: fixed;
        right: 0;
        top: 0;
        bottom: 0;
        display: ${(props) => (props.show ? "flex" : "none")};
    }
    @media (max-width: 540px) {
        width: 100%;
    }
`;

const NavRoute = styled.a`
    appearance: none;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
    color: #707070;
    transition: all 0.3s ease;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    cursor: pointer;
    text-align: center;
    box-sizing: border-box;
    &:hover {
        color: #0bbc75;
    }
    &:active {
        color: #00804c;
    }
`;

export { Wrapper, LogoContainer, OptionsContainer, NavRoute, IconContainer };
