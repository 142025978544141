import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Wrapper, Gradient, Card, Title, Text, Blue, BuildingImage } from "./NuestraHistoria.styled";

const NuestraHistoria = () => {
    const image = useStaticQuery(graphql`
        query {
            building: file(relativePath: { eq: "imprenta_del_oriente_conocenos.jpg" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    return (
        <Wrapper id="nuestrahistoria">
            <Gradient />

            <Card>
                <Title>Nuestra historia</Title>
                <Text>
                    Es una empresa familiar, fundada por nuestro padre, Rubén Rodríguez Cano, contamos con 50 años de
                    experiencia, esto es gracias a la <Blue>honestidad y compromiso</Blue> que siempre hemos brindado a
                    nuestros clientes.
                </Text>
            </Card>
            <BuildingImage fluid={image.building.childImageSharp.fluid} alt="Imprenta del oriente" className="image" />
        </Wrapper>
    );
};

export default NuestraHistoria;
