import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";

const Wrapper = styled(BackgroundImage)`
    width: 100%;
    height: calc(100vh - 60px);
    box-sizing: border-box;
    display: grid;
    grid-template-rows: 1fr 50px;
    padding: var(--df-padding);
    @media (max-width: 940px) {
        padding: var(--940-padding);
    }
    @media (max-width: 540px) {
        padding: 0;
    }
`;

const Title = styled.h2`
    font-family: Arial, Helvetica, sans-serif;
    font-size: 58px;
    margin: 0px;
    color: #00152d;
`;
const SubTitle = styled(Title)`
    font-size: 44px;
`;

const Text = styled.span`
    font-family: Arial, Helvetica, sans-serif;
    font-size: 26px;
    margin: 20px 0;
    color: #2c3138;
`;

const Frame = styled.div`
    border: 10px solid #2f3744;
`;
const Gradient = styled.div`
    background: rgb(162, 255, 0);
    background: linear-gradient(180deg, rgba(162, 255, 0, 1) 0%, rgba(0, 111, 90, 1) 100%);
`;

const Info = styled.div`
    background-color: white;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px;
    box-shadow: -10px 1px 35px -5px rgba(0, 0, 0, 0.14);
    -webkit-box-shadow: -10px 1px 35px -5px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: -10px 1px 35px -5px rgba(0, 0, 0, 0.14);
    box-sizing: border-box;
    ${Title} {
        width: 70%;
    }
    ${SubTitle} {
        width: 70%;
    }
    ${Text} {
        width: 70%;
    }
`;

const Container = styled.div`
    grid-column: 1;
    grid-row: 1;
    display: grid;
    grid-template-rows: repeat(24, 1fr);
    grid-template-columns: repeat(36, 1fr);
    box-sizing: border-box;
    ${Info} {
        grid-row: 6/16;
        grid-column: 2/20;
        z-index: 20;
    }
    ${Frame} {
        grid-row: 5/17;
        grid-column: 1/24;
        z-index: 15;
    }
    ${Gradient} {
        grid-row: 7/18;
        grid-column: 3/21;
        z-index: 10;
    }
    @media (max-width: 1490px) {
        // 24r 36c
        ${Info} {
            grid-row: 6/16;
            grid-column: 2/26;
        }
        ${Frame} {
            grid-row: 5/17;
            grid-column: 1/24;
        }
        ${Gradient} {
            grid-row: 7/18;
            grid-column: 3/21;
        }
    }
    @media (max-width: 1166px) {
        // 24r 36c
        ${Info} {
            grid-row: 6/16;
            grid-column: 2/30;
            ${Text}, ${Title}, ${SubTitle} {
                width: 100%;
            }
        }
        ${Frame} {
            grid-row: 5/17;
            grid-column: 1/32;
        }
        ${Gradient} {
            grid-row: 7/18;
            grid-column: 3/28;
        }
    }
    @media (max-width: 690px) {
        // 24r 36c
        ${Info} {
            height: fit-content;
            grid-row: 6/16;
            grid-column: 2/34;
            ${Title}, ${SubTitle} {
                font-size: 40px;
            }
            ${Text} {
                font-size: 20px;
            }
        }
        ${Frame} {
            grid-row: 5/17;
            grid-column: 1/36;
        }
        ${Gradient} {
            grid-row: 7/18;
            grid-column: 3/35;
        }
    }
    @media (max-width: 605px) {
        // 24r 36c
        ${Info} {
            grid-row: 5/22;
            grid-column: 2/34;
            ${Title}, ${SubTitle} {
                font-size: 40px;
            }
            ${Text} {
                font-size: 20px;
            }
        }
        ${Frame} {
            grid-row: 4/20;
            grid-column: 1/36;
        }
        ${Gradient} {
            grid-row: 8/18;
            grid-column: 4/35;
        }
    }
    @media (max-width: 386px) {
        // 24r 36c
        ${Info} {
            grid-row: 5/22;
            grid-column: 2/34;
        }
        ${Frame} {
            grid-row: 4/20;
            grid-column: 1/36;
        }
        ${Gradient} {
            grid-row: 8/19;
            grid-column: 4/35;
        }
    }
    @media (max-width: 340px) {
        // 24r 36c
        ${Info} {
            grid-row: 5/22;
            grid-column: 2/34;
        }
        ${Frame} {
            grid-row: 4/22;
            grid-column: 1/36;
        }
        ${Gradient} {
            grid-row: 8/24;
            grid-column: 4/35;
        }
    }
    
`;

const Button = styled.div`
    appereance: none;
    background-color: #f3de39;
    width: fit-content;
    height: auto;
    padding: 10px 34px;
    border-radius: 5px;
    font-family: Arial, Helvetica, sans-serif;
    cursor: pointer;
    ${".icon"} {
        margin-left: 8px;
    }
`;

export { Wrapper, Title, Text, Info, Container, SubTitle, Button, Frame, Gradient };
