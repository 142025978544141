import styled from "styled-components";
import { Title as T, Frame as F, Gradient as G, BuildingImage as BI } from "../../styles/styled";

const Title = styled(T)`
    border: 0;
    padding: 0;
`;


const Graphic = styled.div`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(12, 1fr);
`;

const SectionList = styled.div`
    ${Title} {
        color: #5e5e5e;
        font-size: 26px;
    }
`;


const List = styled.ul`
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    box-sizing: border-box;
`;

const Item = styled.li`
    line-height: 44px;
    font-family: Arial, Helvetica, sans-serif;
    color: #4d4c4c;
    font-size: 22px;
    box-sizing: border-box;
`;
const ItemSpace = styled(Item)`
    margin-left: 24px;
`;

const Frame = styled(F)`
    z-index: 9;
`;
const Gradient = styled(G)`
    z-index: 8;
`;
const BuildingImage = styled(BI)`
    z-index: 10;
`;

const SectionlistLeft = styled(SectionList)``;
const SectionlistRight = styled(SectionList)`
    ${Title} {
        //text-align: center;
    }
    ${List} {
        box-sizing: border-box;
        ${Item}{
            line-height: 60px;
        }
    }
`;


const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr) 80px 1fr;
    grid-template-rows: 100px auto auto;
    padding: var(--df-padding);
    ${Title} {
        grid-column: 1;
        grid-row: 2;
    }
    ${SectionlistLeft} {
        grid-column: 1;
        grid-row: 3;
    }
    ${SectionlistRight} {
        grid-column: 4;
        grid-row: 3;
    }
    ${Graphic} {
        grid-column: 2;
        grid-row: 2/4;
        ${Frame} {
            grid-column: 1/10;
            grid-row: 1/11;
        }
        ${Gradient} {
            grid-column: 4/12;
            grid-row: 4/13;
        }
        ${BuildingImage} {
            grid-column: 2/11;
            grid-row: 2/12;
        }
    }
    @media (max-width: 1307px) {
        grid-template-columns: repeat(2, 1fr) 0px 1fr;
        ${Graphic} {
            grid-column: 2;
            grid-row: 3;
            ${Frame} {
                grid-column: 1/10;
                grid-row: 1/10;
            }
            ${Gradient} {
                grid-column: 4/13;
                grid-row: 4/12;
            }
            ${BuildingImage} {
                grid-column: 2/12;
                grid-row: 2/11;
            }
        }
        ${SectionlistRight} {
            padding-left: 40px;
        }
        ${SectionlistLeft} {
            padding-right: 20px;
        }
    }
    @media (max-width: 1135px) {
        ${Item} {
            font-size: 18px;
        }
    }
    @media (max-width: 940px) {
        //padding: var(--940-padding);
        padding-right: 0px;
    }
    @media (max-width: 820px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto auto;
        padding-top: 80px;
        ${Title} {
            grid-column: 1;
            grid-row: 1;
        }
        ${SectionlistLeft} {
            grid-column: 1;
            grid-row: 2;
            display: flex;
            flex-direction: column;
            ${Title} {
                margin-bottom: 0px;
            }
            ${List} {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                list-style: disc;
                ${Item} {
                    width: 100%;
                    box-sizing: border-box;
                }
                ${ItemSpace} {
                    font-size: 16px;
                }
            }
        }
        ${Graphic} {
            grid-column: 2;
            grid-row: 1/4;
            ${Frame} {
                grid-row: 2/12;
            }
            ${BuildingImage} {
                grid-row: 1/13;
                grid-column: 3/13;
            }
            ${Gradient} {
                grid-row: 2/13;
                grid-column: 2/13;
            }
        }
        ${SectionlistRight} {
            margin-top: 18px;
            grid-column: 1;
            grid-row: 3;
            display: flex;
            flex-direction: column;
            padding-left: 0px;
            ${Title} {
                margin-bottom: 0px;
            }
            ${List} {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                justify-content: flex-start;
                list-style: disc;
                line-height: 40px;
                ${Item} {
                    width: 100%;
                    box-sizing: border-box;
                }
                ${ItemSpace} {
                    font-size: 16px;
                }
            }
        }
    }
    @media (max-width: 500px) {
        padding: var(--940-padding);
        padding-right: 0px;
        padding-top: 60px;
        padding-left: 40px;
        grid-template-columns: 1fr .5fr;
    }
    @media (max-width: 417px){
        grid-template-rows: 300px auto auto auto;
        grid-template-columns: 1fr;
        padding-top: 20px;
        ${Title}{
            margin-top: 20px;
            grid-column: 1;
            grid-row: 2;
        }
        ${Graphic}{
            grid-row: 1;
            grid-column: 1;
        }
        ${SectionlistLeft}{
            grid-row: 3;
            grid-column: 1;
        }
        ${SectionlistRight}{
            grid-row: 4;
            grid-column: 1;
        }
    }
`;

export {
    Wrapper,
    Title,
    Graphic,
    Frame,
    Gradient,
    BuildingImage,
    SectionlistLeft,
    SectionlistRight,
    List,
    Item,
    ItemSpace,
};
