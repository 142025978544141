import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";
import { Frame, SolidGradient, Title as DefaultTitle, Text as DefaultText, Card as DefaultCard } from "../../styles/styled";

const Wrapper = styled(BackgroundImage)`
    width: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-rows: 80px auto 80px;
    height: fit-content;
    margin-top: 100px;
    @media (max-width: 940px) {
        padding: var(--940-padding);
    }
    @media (max-width: 540px) {
        padding: 0;
        grid-template-rows: 40px auto 40px;
    }
`;



const Gradient = styled(SolidGradient)`

`;

const Text = styled(DefaultText)`
    line-height: normal;
    font-weight: lighter;
`;

const JustifiedText = styled(Text)`
    text-align: center;
    margin-top: ${(props) => (props.space ? "40px" : "")};
`;

const Card = styled(DefaultCard)`
    padding-right: 80px;
`;

const Title = styled(DefaultTitle)`
    border: none;
`;

const Container = styled.div`
    grid-row: 2;
    display: grid;
    grid-template-rows: 30px 30px auto 30px 30px;
    grid-template-columns: 1fr 1fr 80px auto 80px;
    ${Card} {
        grid-row: 2/4;
        grid-column: 4/6;
    }
    ${Gradient} {
        grid-row: 3/6;
        grid-column: 3/5;
        z-index: 8;
    }
    ${Frame} {
        grid-row: 1/6;
        grid-column: 2/5;
        z-index: 9;
    }
    @media (max-width: 1457px) {
        ${Gradient} {
            opacity: 0.7;
            grid-column: 3/5;
        }
        ${Frame} {
            grid-column: 3/6;
        }
    }
    @media (max-width: 540px) {
        ${Card} {
            padding: 30px;
        }
    }
    @media (max-width: 480px) {
        ${Card} {
            grid-column: 1/6;
        }
        ${Gradient} {
            grid-row: 1/6;
            grid-column: 2/5;
        }
        ${Frame} {
            grid-column: 2/6;
        }
    }
    @media (max-width: 400px) {
        ${Card} {
            ${Text} {
                text-align: center;
                line-height: 30px;
            }
            ${Title} {
                text-align: center;
            }
        }
    }
`;

export { Wrapper, Frame, Gradient, Container, Title, Text, Card, JustifiedText };
