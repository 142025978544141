import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import {
    Wrapper,
    Title,
    Graphic,
    Frame,
    Gradient,
    BuildingImage,
    SectionlistLeft,
    SectionlistRight,
    List,
    Item,
    ItemSpace,
} from "./ProoductosServicios.styled";

const ProductosServicios = () => {

    const image = useStaticQuery(graphql`
        query {
            building: file(relativePath: { eq: "imprentas_en_monterry.jpg" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    return (
        <Wrapper id="productosyservicios">
            <Title>PRESENTACIÓN</Title>

            <SectionlistLeft>
                <Title>Productos y servicios</Title>
                <List>
                    <Item>Impresión Digital.</Item>
                    <Item>Impresióm de papelería:</Item>
                    <ItemSpace>En cualquier clase de papel o cartulina.</ItemSpace>
                    <ItemSpace>A color (selección de color).</ItemSpace>
                    <ItemSpace>Formatos para plantas o empresas.</ItemSpace>
                    <ItemSpace>Tarjetas de presentación.</ItemSpace>
                    <ItemSpace>Menús.</ItemSpace>
                    <Item>Trípticos, Flyers.</Item>
                    <Item>Sellos de goma y autoentintables.</Item>
                    <Item>Lonas, letreros, etiquetas en papel o vinil.</Item>
                    <Item>Imanes.</Item>
                    <Item>Bolsas Ecológicas.</Item>
                    <Item>Papelería impresa en general.</Item>
                </List>
            </SectionlistLeft>

            <SectionlistRight>
                <Title>Facturas Electrónicas</Title>
                <List>
                    <Item>Folios de lecturas.</Item>
                    <Item>Complementos de pago.</Item>
                    <Item>Timbrado.</Item>
                    <Item>Somos distribuidores autorizados.</Item>
                    <Item>Sellos Digitales.</Item>
                    <Item>Firma Electrónica.</Item>
                    <Item>Venta y Asesoría.</Item>
                </List>
            </SectionlistRight>

            <Graphic>
                <Frame />
                <Gradient />
                <BuildingImage
                    fluid={image.building.childImageSharp.fluid}
                    alt="Imprenta del oriente"
                    className="image"
                />
            </Graphic>
        </Wrapper>
    );
};

export default ProductosServicios;
