import styled from "styled-components";
import { Title as T, Text } from "../../styles/styled";



const Title = styled(T)`
    border-color: #4caf73;
    margin: auto;
`;

const Subtitle = styled(Text)`
    margin-top: 40px;
    font-size: 22px;
`;

const Value = styled(Text)`
    color: #525252;
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: ${(props) => (props.bold ? "bolder" : "normal")};
    text-align: center;
`;

const ContactCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 30%;
    max-width: 400px;
    min-width: 300px;
    background-color: white;
    margin: 20px;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: -10px 1px 35px -5px rgba(0, 0, 0, 0.14);
    -webkit-box-shadow: -10px 1px 35px -5px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: -10px 1px 35px -5px rgba(0, 0, 0, 0.14);
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
        box-shadow: -10px 1px 35px -5px rgba(0, 0, 0, 0.24);
        -webkit-box-shadow: -10px 1px 35px -5px rgba(0, 0, 0, 0.24);
        -moz-box-shadow: -10px 1px 35px -5px rgba(0, 0, 0, 0.24);
    }
    @media (max-width: 685px) {
        border-bottom: ${(props) => (props.first ? "1px solid #707070" : "none")};
        max-width: 100%;
    }
    @media (max-width: 480px) {
        width: 100%;
        min-width: 100%;
        margin: 10px;
    }
`;



const FlagContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    background-color: #f2f2f2;
    border: 2px solid #2d2a611a;
    top: -26px;
    padding: 5px 20px;
    border-radius: 5px;
    ${Value} {
        margin-left: 10px;
        //color: white;
    }
`;

const AdressContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    background-color: #f2f2f2;
    bottom: -24px;
    padding: 5px 20px;
    border-radius: 5px;
    border: 2px solid #2d2a611a;
    ${Value} {
        margin-left: 8px;
    }
    @media (max-width: 730px) {
        flex-direction: column;
        height: 140px;
        bottom: -120px;
        width: 60%;
    }
`;

const CardsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 40px;
`;

const Flag = styled.img`
    width: 40px;
    height: 25px;
`;

const Route = styled.span`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    width: 100%;
    transition: all 0.3s ease;
    &:hover {
        background-color: #eaeaea;
    }
    ${".icon"} {
        color: #707070;
    }
`;

const Link = styled.a`
    color: #393939;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    ${'.icon'}{
        margin-right: 10px;
    }
`;

const SocialWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 1000px;
    margin-top: 40px;
    ${".icon"} {
        font-size: 22px;
    }
`;

const Wrapper = styled.section`
    margin-top: 80px;
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px 80px;
    box-sizing: border-box;
    overflow: hidden;
    @media (max-width: 940px) {
        padding: 50px 30px;
    }
    @media (max-width: 685px) {
        ${Value} {
            line-height: 30px;
        }
    }
    @media (max-width: 437px) {
        ${Link} {
            display: none;
        }
    }
    @media (max-width: 386px) {
        padding: 50px 0px;
        ${ContactCard} {
            width: 100%;
            max-width: 100%;
            min-width: 100%;
            ${Value} {
                font-size: 16px;
                display: inline-block;
            }
        }
    }
`;

const Group = styled.div`
    border: 2px solid #2d2a611a;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    max-width: 1600px;
    margin: 50px 0px;
    position: relative;
    padding: 40px 0px;
    padding-bottom: 50px;
    border-radius: 8px;
    box-sizing: border-box;
    @media (max-width: 730px) {
        margin-bottom: 120px;
    }
    @media (max-width: 480px) {
        //border-left: 0;
        //border-right: 0;
        //border-bottom: 0;
        padding: 20px;
        border-radius: 0;
        margin: 30px 0;
        ${AdressContainer} {
            position: relative;
            width: 100%;
            bottom: 0;
            margin-top: 0px;
            border: 0;
        }
    }
    @media (max-width: 386px) {
        padding: 20px 10px;
    }
`;

export {
    Wrapper,
    Title,
    Subtitle,
    CardsContainer,
    ContactCard,
    Value,
    FlagContainer,
    Flag,
    Link,
    Route,
    SocialWrapper,
    Group,
    AdressContainer,
};