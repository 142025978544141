import React from "react";
import Helmet from "react-helmet";
import { Navbar, Snackbar, MainLogo, Cover, NuestraHistoria, Objetivos, ProblemasSolucion, ProductosServicios, Contact } from "../components";
import { Main } from "../styles/styled";

// markup
const IndexPage = () => {
    return (
        <>
            <Helmet>
                <title>Imprenta del Oriente</title>
                <meta name="description" content="Imprenta del oriente" />
                <meta name="theme-color" content="#008f68" />
                <meta
                    name="viewport"
                    content="height=device-height, width=device-width, initial-scale=1.0,
                minimum-scale=1.0, maximum-scale=1.0, user-scalable=no, target-densitydpi=device-dpi"
                />
            </Helmet>
            <Main>
                <Navbar />
                <Snackbar />

                <MainLogo />
                <Cover />
                <NuestraHistoria />
                <Objetivos />
                <ProblemasSolucion />
                <ProductosServicios />
                <Contact />
                <MainLogo />
            </Main>
        </>
    );
};

export default IndexPage;
