import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { sendTo, socialMediaRoutes } from "../../utils/routes";
import { Wrapper, Container, Route } from "./Snackbar.styled";

const Snackbar = () => {
    return (
        <Wrapper>
            <Container>
                {socialMediaRoutes.map((route) => (
                    <Route onClick={() => sendTo(route.route)}>
                        <FontAwesomeIcon icon={route.icon} className="icon" />
                    </Route>
                ))}{" "}
            </Container>
        </Wrapper>
    );
};

export default Snackbar;
